import React from "react"
import "./footer.css"
import { FooterLinks } from "../utils/commonComponents"

const Footer = () => {
  return (
    <footer>
      <section className="footer">
        <div className="footer__addr">
          <h1 className="footer__logo">
            <FooterLinks link="https://www.devonyu.com" linkName="Devon Yu" />
          </h1>
        </div>

        <ul className="footer__nav">
          <li className="nav__item">
            <h2 className="nav__title">
              <FooterLinks
                link="https://www.linkedin.com/in/devonyu"
                linkName="LINKEDIN"
              />
            </h2>
          </li>

          <li className="nav__item">
            <h2 className="nav__title">
              <FooterLinks
                link="https://www.github.com/devonyu"
                linkName="GITHUB"
              />
            </h2>
          </li>

          <li className="nav__item">
            <h2 className="nav__title">
              <FooterLinks
                link="mailto:devonyu415@gmail.com?subject=Lets%20Chat&body=Hello%20Devon"
                linkName="EMAIL"
              />
            </h2>
          </li>

          <li className="nav__item">
            <h2 className="nav__title">
              <FooterLinks
                link="https://www.devonyu.com/devonyuresume.pdf"
                linkName="RESUME"
              />
            </h2>
          </li>
        </ul>

        <div className="madewith">
          <span>
            Made with <span className="heart">♥</span> from San Francisco
          </span>
        </div>
      </section>
    </footer>
  )
}

export default Footer
