import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Sectionlink = styled.h1`
  font-size: 3rm;
  span {
    visibility: hidden;
  }
  :hover {
    cursor: pointer;
    span {
      color: red;
      visibility: visible;
    }
  }
`

const SectionHeaderStyled = styled.h1`
  text-align: left;
  font-size: 3rm;
  margin-top: 1em;
`
const FooterLinkStyle = styled.a`
  text-transform: uppercase;
  font-size: 1rem;
  margin-left: 0.75rem;
  color: white;
  vertical-align: middle;
  align-items: center;
`

export const IndexLink = props => (
  <Sectionlink>
    <Link
      to={props.linkTo}
      style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.8)" }}
    >
      {props.section} <span> &#8680;</span>
    </Link>
  </Sectionlink>
)

export const SectionHeader = props => (
  <SectionHeaderStyled>{props.header}</SectionHeaderStyled>
)

export const FooterLinks = props => (
  <FooterLinkStyle href={props.link}>{props.linkName}</FooterLinkStyle>
)
