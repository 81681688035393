const nextLens = {
  title: "NextLens.io",
  intro: "Lens recommendations system for beginner and novice photographers",
  description:
    "Curates a list of lenses evaluated from user affinity toward certain photography categories",
  features:
    "Swipable images to like/dislike an image (I.E Tinder swipes) Helps photographers find lenses that will deliver images of their preference Integrates with FlickR API to show real images taken with specific lenses",
  techStack:
    "React , Node + Express, PostgreSQL, Semantic UI React, Redis, Travis CI, Deployed on Heroku, Utilizes Unsplash and Flickr API",
  github: "https://github.com/devonyu/nextLens-io",
  active: "https://www.nextlens.io",
  coverage:
    "https://coveralls.io/repos/github/devonyu/nextLens-io/badge.svg?branch=master",
  build: "https://travis-ci.org/devonyu/nextLens-io.svg?branch=master",
  mainImage: "nextlensmacbook.png",
  backgroundImage: "nextlensBackground.jpg",
  upcomingFeatures:
    "React Native implementation, Multi-Armed Bandit Algorithm to dynamically serve images",
}

const taskr = {
  title: "TaskR",
  intro: "Tasking Application utilizing Markdown editor and future team usage",
  description:
    "TaskR builds the most powerful and flexible tools for task management",
  features:
    "Taskr's View component includes the most important data to help organize and sort tasks. Customize how tasks are shown within the UI. From sorting, filtering, and searching tasks a user can have complete control on what tasks are shown.",
  techStack:
    "React , Node + Express,  Material UI, AWS EC2, AWS DynamoDB, Travis CI, Coveralls, Jest",
  github: "https://github.com/devonyu/taskr",
  active: "https://taskronline.netlify.com/",
  coverage:
    "https://coveralls.io/repos/github/devonyu/taskr/badge.svg?branch=master",
  build: "https://travis-ci.com/devonyu/taskr.svg?branch=master",
  mainImage: "iphone_animated.svg",
  backgroundImage: "taskrBackground.png",
}

const pinterestingAnalytics = {
  title: "Pinteresting Analytics",
  intro:
    "SOA based application for a Pinterest clone to find optimal ratio of ads for users",
  description:
    "Optimized my service gateway (Ad Aggregator) with an increased load of request via Amazon SQS Message bussing, Node Clustering and Database denormalization. Visualized Data with the ELK Stack.",
  features:
    "Backend App to learn distrubuted systems design and to optimze backend performances",
  techStack:
    "Node.JS, Express, Elastic Search, Kibana, Logstash, MySql, Amazon SQS",
  github: "https://github.com/devonyu/Pinteresting-Analytics",
  mainImage: "pinteresting.png",
  backgroundImage: "pinterestingBackground.png",
}

const watchPotatoe = {
  title: "Watch Potato",
  intro: "Movie and TV show scheduler targeted for Binge Watchers",
  description:
    "Jumped into an existing codebase and implemented a profile page for users. Integrated Twilio API to send users mobile notifications for reminders and ratings",
  features:
    "Utilizes the TMDB API to gather latest meta data for TV shows and Movies.  Scheduling interation with Google Calender. SMS notications integration with Twilio API",
  techStack:
    "React, Node.js+Express, Postgres, MongoDB, Semantic UI React, MovieDB Api and Twilio Api",
  github: "https://github.com/devonyu/taskr",
  mainImage: "watchpotatomacbook.png",
  backgroundImage: "watchPotatoBackground.png",
}

const facebook = {
  company: "Facebook",
  role: "Software Engineer | Menlo Park",
  description:
    "As part of the Enterprise Engineering team, I helped develop internal tools for the finance team. JS ES6, React, Undux, Relay, GraphQL, Hack, SQL. Created Web portal to onboard and manage suppliers that worked with Facebook",
  fullDescription: [
    "As part of the Enterprise Engineering team, I helped develop internal tools that benefited the finance team. We developed a web portal utilizing the Facebook stack to improve the UX of editing supplier data.",
    "I specifically worked on editing form inputs that required custom business logic (localization/international requirements), creating UI/UX warnings and validations, squashing bugs, and utlized the latest API's to improve overall performance.",
    "Collaborated with PM’s, designers, and enterprise engineers to iterate on design and implementation of product features.",
  ],
  imageURL: "coding",
  tags: ["React", "GraphQL", "Relay", "Hack", "SQL", "Scrum", "Agile"],
  technicalSkills:
    "JavaScript ES6, HTML5, CSS3, React JS + Latest Hooks, Undux, GraphQL, Relay, Hack/PHP, SQL",
  position: "left",
}

const hackReactor = {
  company: "Hack Reactor @ Galvanize",
  role: "SWE Teacher Assistant | SF & Remote",
  description:
    "Taught Students Javascript fundamentals including web development fundamentals, Programming/coding best practices.  Conducted lectures, mock technical interview, townhalls and answered helpdesk requests",
  fullDescription: [
    "Taught, mentored, and advised students through Hack Reactors curriculum focusing on JavaScript fundamentals.",
    "Conducted Technical Interviews to evaluate Student Readiness & deliver feedback for identifying strong/weak points",
    "Answered help tickets, tracked student performance, conducted lectures, and hosted town hall meetings",
  ],
  imageURL: "teach",
  tags: [
    "Teaching",
    "Web Development",
    "Mentoring",
    "Pair Programming",
    "Technical Interviews",
    "Lecturing",
    "Public Speaking",
  ],
  position: "right",
}

const freeLance = {
  company: "Devonyu.com",
  role: "Freelance Developer | SF + Remote",
  description:
    "Helped clients build portfolios, blogs, and full stack applications",
  fullDescription: [
    "Collaborated with clients to design and develop web applications, portfolio pages, and personal blogs",
    "Utilized webpage optimization techniques to reduce load times including CDN usage, minification, and caching",
  ],
  imageURL: "freelance",
  tags: [
    "Freelance",
    "Portfolio",
    "Blogs",
    "Full Stack applications",
    "Devops",
  ],
  technicalSkills:
    "JavaScript ES6, HTML5, CSS3, React JS + Latest Hooks, NodeJS, Express, Redis, AWS DynamoDB, EC2, Jest, Coveralls, Travis CI, Heroku, Docker, Linode, Linux, NoSQL, SQL, Gatsby, Styled Components, Semantic UI, Material UI,",
  position: "left",
}

const shortAbout = [
  "My name is Devon Yu, and I'm a software engineer based in San Francisco.  I have professional experience developing Full-Stack applications at Facebook within the Enterprise Engineering team.  Along with developing professionally, I'm very passionate about programming and have built multiple applications from ideation all the way to deployment in my spare time.",
  "I'm always eager to learn new technologies and am constantly sharpening my technical skills by reading documentation, reading about new concepts, and translating it all into code.  I welcome complex tasks that test my problem-solving / analytical skills and value feedback from peers.",
  "When I'm not programming, I enjoy spending my time on some of my favorite hobbies including: photography, training for races, snowboarding, playing basketball, DIY home improvement projects, playing board games/videogames, teaching my dog new tricks, and watching critically acclaimed TV shows and movies.",
]

const longAbout =
  "I'm a Software Developer based in San Francisco, California. Born and raised in the Bay Area, UCSD graduate, tinkerer and Self-Taught Programmer. Strong interest in full-stack development focusing on well designed, clean, responsive, and scalable code. I strive on creating/implementing/testing features that improves overall user experience, learning new technologies, and optimizing services to scale. I have experience developing in an Agile environment and enjoy solving challenges that arise during the software development cycle. When I'm not coding, I enjoy the following: Photography, Cycling, Running, Lifting, Basketball, Gaming + Board Games, Traveling, Tinkering / Modding / DIY (software/hardware/cars/you name it) and cooking. I've grown to enjoy writing about my experiences in software development and life in general on my blog."

const frontEnd = [
  { name: "Client Side" },
  { name: "JavaScript", image: "frontend/javascript.svg" },
  { name: "HTML5", image: "frontend/html5.svg" },
  { name: "CSS3", image: "frontend/css3.svg" },
  { name: "React", image: "frontend/react.svg" },
  { name: "Redux", image: "frontend/redux.svg" },
  { name: "React Router", image: "frontend/reactrouter.svg" },
  { name: "Gatsby", image: "frontend/gatsby.svg" },
  { name: "Apollo", image: "frontend/apollo.svg" },
  { name: "Relay", image: "frontend/relay.svg" },
  { name: "jQuery", image: "frontend/jquery.svg" },
  { name: "Material UI", image: "frontend/materialui.svg" },
  { name: "Semantic UI", image: "frontend/semanticui.svg" },
]

const backEnd = [
  { name: "Server Side + DBMS" },
  { name: "Node JS", image: "backend/nodejs.svg" },
  { name: "Express", image: "backend/express.svg" },
  { name: "PostgreSQL", image: "backend/postgresql.svg" },
  { name: "MySQL", image: "backend/mysql.svg" },
  { name: "MongoDB", image: "backend/mongodb.svg" },
  { name: "Redis", image: "backend/redis.svg" },
  { name: "Hack", image: "backend/hack.svg" },
  { name: "Nginx", image: "backend/nginx.svg" },
  { name: "Ruby", image: "backend/ruby.svg" },
  { name: "Python", image: "backend/python.svg" },
  { name: "Flask", image: "backend/flask.svg" },
]

const devops = [
  { name: "Devops" },
  { name: "AWS", image: "devops/aws.svg" },
  { name: "AWS EC2", image: "devops/awsec2.svg" },
  { name: "DynamoDB", image: "devops/awsdynamodb.svg" },
  { name: "AWS S3", image: "devops/awss3.svg" },
  { name: "AWS SQS", image: "devops/awssqs.svg" },
  { name: "Docker", image: "devops/docker.svg" },
  { name: "Heroku", image: "devops/heroku.svg" },
  { name: "Linode", image: "devops/linode.svg" },
  { name: "Linux", image: "devops/linux.svg" },
  { name: "Npm", image: "devops/npm.svg" },
  { name: "Travis CI", image: "devops/travisci.svg" },
  { name: "Ubuntu", image: "devops/ubuntu.svg" },
]

const toolsTesting = [
  { name: "Tools + Testing" },
  { name: "Flow", image: "devops/flow.svg" },
  { name: "Chai", image: "devops/chai.svg" },
  { name: "Coveralls", image: "devops/coveralls.svg" },
  { name: "Postman", image: "devops/postman.svg" },
  { name: "VS Code", image: "devops/vscode.svg" },
  { name: "Mocha", image: "devops/mocha.svg" },
  { name: "Jest", image: "devops/jest.svg" },
  { name: "Git", image: "devops/git.svg" },
  { name: "Webpack", image: "devops/webpack.svg" },
]

export const projects = [nextLens, taskr, pinterestingAnalytics, watchPotatoe]
export const experiences = [facebook, hackReactor, freeLance]
export const about = { short: shortAbout, long: longAbout }
export const techStack = [frontEnd, backEnd, devops, toolsTesting]
