import React, { useState } from "react"
import styled from "styled-components"
import Headroom from "react-headroom"
import { Link } from "gatsby"

import useWindowDimensions from "../utils/useWindowDimensions"

const LogoText = styled.span`
  color: white;
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
  justify-content: center;
  margin-left: 0.75rem;
  padding-top: 0.5rem;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  @media (max-width: 700px) {
    margin-right: calc(0.75rem + 50px);
    padding-right 30vw;
  }
`

const Nav = styled.nav`
  display: flex;
  height: 50px;
  float: right;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.75em;
  z-index: 9999;
  a {
    color: white;
    text-decoration: none;
    vertical-align: middle;
    margin-left: 1.5rem;
    transition: all 0.3s;
    &:hover {
      color: red;
    }
    &:focus {
      color: white;
    }
  }
  @media (max-width: 700px) {
    display: none;
  }
`
const Overlay = styled.div`
  height: calc(100% - 50px);
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 50px;
  left: 0;
  background-color: black;
  overflow-x: hidden;
  transition: 0.5s;
  &.hidden {
    display: none;
  }
  &.open {
    width: 100vw;
  }
`

const OverlayContent = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  a {
    font-size: 2em;
    text-decoration: none;
    color: white;
    padding: 1em 10px;
  }
  a:hover {
    color: red;
  }
`

const Bar = styled.div`
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 6px;
  transition: 0.4s;
  &.one {
    background-color: red;
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  &.two {
    opacity: 0;
  }
  &.three {
    background-color: red;
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
`

const Container = styled.div`
  display: inline-block;
  cursor: pointer;
  @media (min-width: 701px) {
    display: none;
  }
`
const Hamburger = props => (
  <Container onClick={props.onClick}>
    <Bar className={props.isOpen ? "one" : ""} />
    <Bar className={props.isOpen ? "two" : ""} />
    <Bar className={props.isOpen ? "three" : ""} />
  </Container>
)

const Header = () => {
  const [isNavOpen, setNav] = useState(false)
  const { width } = useWindowDimensions()
  const toggleNav = () => {
    if (!isNavOpen || width >= 700) {
      document.body.style.overflow = "hidden"
    } else if (isNavOpen || width < 700) {
      document.body.style.overflow = "unset"
    }
    setNav(!isNavOpen)
  }

  return (
    <>
      <Headroom
        calcHeightOnResize
        // onPin={() => console.log("pinned")}
        // onUnpin={() => console.log("unpinned")}
        style={{
          zIndex: 9999,
          height: "50px",
          background: "black",
          boxShadow: "3px 10px 10px rgba(0,0,0,0.25)",
          display: "flex",
          justifyContent: "space-between",
        }}
        disable={isNavOpen}
      >
        <Hamburger onClick={toggleNav} isOpen={isNavOpen && width < 700} />
        <Link to="/" style={{ textDecoration: "none" }} id="logoText">
          <LogoText
            onClick={() => {
              document.body.style.overflow = "unset"
            }}
          >
            Devon Yu
          </LogoText>
        </Link>
        <Nav>
          <Link to="/about">about</Link>
          <Link to="/experience">experience</Link>
          <Link to="/projects">projects</Link>
          <Link to="/skills">skills</Link>
          <Link to="/contact">contact</Link>
          <Link to="/resume">resume</Link>
        </Nav>
      </Headroom>
      <Overlay className={isNavOpen && width < 700 ? "open" : "hidden"}>
        <OverlayContent>
          <Link
            to="/"
            onClick={() => {
              document.body.style.overflow = "unset"
            }}
          >
            home
          </Link>
          <Link
            to="/about"
            onClick={() => {
              document.body.style.overflow = "unset"
            }}
          >
            about
          </Link>
          <Link
            to="/experience"
            onClick={() => {
              document.body.style.overflow = "unset"
              toggleNav()
            }}
          >
            experience
          </Link>
          <Link
            to="/projects"
            onClick={() => {
              document.body.style.overflow = "unset"
            }}
          >
            projects
          </Link>
          <Link
            to="/skills"
            onClick={() => {
              document.body.style.overflow = "unset"
            }}
          >
            skills
          </Link>
          <Link
            to="/contact"
            onClick={() => {
              document.body.style.overflow = "unset"
            }}
          >
            contact
          </Link>
          <Link
            to="/resume"
            onClick={() => {
              document.body.style.overflow = "unset"
            }}
          >
            resume
          </Link>
        </OverlayContent>
      </Overlay>
    </>
  )
}

export default Header
